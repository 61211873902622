;(function() {
	var token = $("#meta\\.CurrentToken").attr("content") || (function () {
		var tokentest = /(\?|&)token=([^&#]+)/.exec(window.location.search);
		return !!tokentest ? tokentest[2] : null;
	})();
	
	if (!token){
		return;
	}

	function appendToken(url) {
		if (url && url.search(/(\?|\&)+token=/) === -1) {
			var urlParts = url.split("?");
			var urlParams = new URLSearchParams(urlParts[1]);
			urlParams.append("token", token);
			url = urlParts[0] + "?" + urlParams.toString();
		}
		return url;
	}

	if (window.signalR) { 
		var withUrl = window.signalR.HubConnectionBuilder.prototype.withUrl;
		window.signalR.HubConnectionBuilder.prototype.withUrl = function(url){
			return withUrl.call(this, appendToken(url));
		};
	}

	if ($.ajax) {
		$(document).ajaxSend(function(event, request, settings) {
			if (settings.appendTokenIfRequired !== false) {
				settings.url = appendToken(settings.url);
			}
		});
	}

	$(function () {
		$.fx.off = true;

		$("form").each(function () {
			$(this)
	.append($("<input />")
			.attr({ "type": "hidden", "name": "token" })
			.val(token))
	.data("tokenAppended", true);
		});

		$(document.body).delegate("form", "focus", function () {
			var current = $(this);
			if (!current.data("tokenAppended")) {
				current
						.append($("<input />")
										.attr({ "type": "hidden", "name": "token" })
										.val(token))
						.data("tokenAppended", true);
			}
		});

		$(document.body).delegate("form", "submit", function () {
			var current = $(this);
			if (!current.data("tokenAppended")) {
				current
						.append($("<input />")
										.attr({ "type": "hidden", "name": "token" })
										.val(token))
						.data("tokenAppended", true);
			}
		});

		$("a").each(function () {

			var current = $(this);
			var href = current.attr("href");
			if (href != undefined && href[0] != "#" && href.search("token=") < 0) {
				href = href.split("#");
				current.attr("href", href[0] + (href[0].search(/\?/) > 0 ? "&token=" : "?token=") + token + (href[1] ? "#" + href[1] : ""));
			}
		});

		$(document.body).delegate("a", "focus", function () {
			var current = $(this);
			var href = current.attr("href");
			if (href != undefined && href[0] != "#" && href.search("token=") < 0) {
				href = href.split("#");
				current.attr("href", href[0] + (href[0].search(/\?/) > 0 ? "&token=" : "?token=") + token + (href[1] ? "#" + href[1] : ""));
			}
		});

		$(document.body).delegate("a", "click", function () {
			var current = $(this);
			var href = current.attr("href");
			if (href != undefined && href[0] != "#" && href.search("token=") < 0) {
				href = href.split("#");
				current.attr("href", href[0] + (href[0].search(/\?/) > 0 ? "&token=" : "?token=") + token + (href[1] ? "#" + href[1] : ""));
			}
		});

		if (window.Sammy && window.Sammy.EventContext){
			var getUrl = window.Sammy.EventContext.prototype.getUrl;
			window.Sammy.EventContext.prototype.getUrl = function() {
				return appendToken(getUrl.apply(this, arguments));
			};
		}

		if (window.$data) {
			var prepareRequest = window.$data.storageProviders.oData.oDataProvider.prototype.prepareRequest;
			window.$data.storageProviders.oData.oDataProvider.prototype.prepareRequest = function(requestData) {
				var request = requestData[0];
				request.requestUri = appendToken(request.requestUri);
				return prepareRequest.call(this, requestData);
			}
		}

		if (window.ko && window.ko.components.loaders.length > 2) {
			var loadTemplate = window.ko.components.loaders[1].loadTemplate;
			window.ko.components.loaders[1].loadTemplate = function(name, templateConfig, callback) {
				templateConfig.url = appendToken(templateConfig.url);
				return loadTemplate.call(this, name, templateConfig, callback);
			}
		}
	});

	if (window.fetch && window.Helper && window.Helper.Url) {
		var fetch = window.fetch;
		window.fetch = function() {
			var url = arguments[0];
			arguments[0] = url && window.Helper.Url.qualifyURL(url).indexOf(location.host) !== -1 ? appendToken(url) : url;
			return fetch.apply(this, arguments);
		}
	}
})();