import {HelperString} from "./Helper.String";

export class HelperConfirm {
	static genericConfirm(config) {
		const deferred = $.Deferred();
		HelperConfirm.genericConfirmAsync(config).then(result => {
			if (result) {
				deferred.resolve();
			} else {
				deferred.reject();
			}
		});
		return deferred.promise();
	}

	static async genericConfirmAsync(config): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const activeModal = $(".modal:visible");
			activeModal.hide();
			var waitForActiveSwalToHide = $(".hideSweetAlert:visible").length > 0 ? 200 : 0;
			setTimeout(function () {
				window.swal({
					title: config.title || "",
					text: config.text || "",
					type: config.type,
					showCancelButton: config.showCancelButton !== undefined ? config.showCancelButton : true,
					confirmButtonText: config.confirmButtonText || HelperString.getTranslatedString("Ok"),
					cancelButtonText: config.cancelButtonText || HelperString.getTranslatedString("Cancel"),
					closeOnConfirm: true
				}, function (isConfirm) {
					setTimeout(function () {
						if (isConfirm) {
							resolve(true);
						} else {
							resolve(false);
						}
						activeModal.show();
					}, 200);
				});
			}, waitForActiveSwalToHide);
		});
	}

	static confirmDelete() {
		return this.genericConfirm({
			text: HelperString.getTranslatedString("ConfirmDelete"),
			type: "error",
			confirmButtonText: HelperString.getTranslatedString("Delete")
		});
	}

	static async confirmDeleteAsync(): Promise<boolean> {
		return this.genericConfirmAsync({
			text: HelperString.getTranslatedString("ConfirmDelete"),
			type: "error",
			confirmButtonText: HelperString.getTranslatedString("Delete")
		});
	}

	static confirmContinue() {
		return this.genericConfirm({
			text: window.Helper.String.getTranslatedString("PendingChangesWarningText"),
			type: "warning",
			confirmButtonText: window.Helper.String.getTranslatedString("Discard")
		});
	}
}
