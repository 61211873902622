import {BrowserMultiFormatReader, BarcodeFormat} from "@zxing/browser";
import {DecodeHintType} from "@zxing/library";

function setupLogin() {
	if (navigator.userAgent.indexOf("Cordova") !== -1) {
		$.cookie("Cordova", true, { path: "/", expires: 36500 });
	}
	if (window.location.search)
		$("#openidloginbutton").attr("href", $("#openidloginbutton").attr("href") + window.location.search);
	$(document)
		.on("click", "#btn-login", function(e) {
			const form = $(this).parents("form");
			e.preventDefault();
			const inputs = form.find("input:visible");
			let valid = true;
			inputs.each(function(i, input) {
				const $input = $(input);
				if ($input.val() == null || $input.val() === "") {
					valid = false;
					const $inputContainer = $input.parents(".fg-line");
					$inputContainer.addClass("has-error");
					const $inputGroup = $input.parents(".input-group");
					if ($inputGroup.length === 0) {
						$inputContainer.addClass("animated shake");
					} else {
						$inputGroup.addClass("animated shake");
					}
				}
			});
			if (valid) {
				if (window.location.hash)
					form.attr("action", form.attr("action") + encodeURIComponent(window.location.hash));
				$("#needRedirect").val(localStorage.getItem("last-user-login") != null ? Boolean(localStorage.getItem("last-user-login") != window.Helper.String.hash($("#email").val())) : false );

				form.submit();
			}
			return false;
		}).on("change, keydown, keyup", ".has-error input", function (e) {
			const errorParent = $(this).parents(".has-error");
			errorParent.removeClass("has-error");
			errorParent.children(".help-block.pre-line").hide();
	}).on("click", ".remove-login", function(e) {
		e.preventDefault();
		$.cookie("Login", null, { path: "/", expires: -1 });
		window.location.reload();
	}).on("click", ".logout-client-selection", function(e) {
		$.cookie("Login", null, { path: "/", expires: -1 });
	}).on("click", "#goto-website", function(e) {
		e.preventDefault();
		$(".app-suggestion").hide();
		$("#openidlogin").removeClass("hide");
		$("#l-login").removeClass("hide");
		$("#l-login").show();
		return false;
	}).on("click", "h4#logo-or-title", function (e) {
		$("#version-number").fadeToggle("medium");
	}).on("show.bs.modal", "#modal", async function (e) {
		const codeReader = new BrowserMultiFormatReader(new Map([[DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE]]]));
		const devices = (await navigator.mediaDevices.enumerateDevices()).filter(val => val.kind === "videoinput" && val.label.search(/ir\s/i) === -1);
		if (devices.length === 0) {
			$("#modal").modal("hide");
			return;
		}
		const device = devices.find(value => value.label.includes("facing back"));
		try {
			await codeReader.decodeFromVideoDevice((device ?? devices[0]).deviceId, "barcodeScannerVideo", (result, err) => {
				if (result) {
					$("#modal").modal("hide");
					window.location = window.Helper.Url.resolveUrl(`~/Home/Index?token=${result.text}`);
				}
			});
		} catch (e) {
			console.error(e);
		}
	}).on("hidden.bs.modal", "#modal", function (e) {
		BrowserMultiFormatReader.releaseAllStreams();
		const stream = $("video")[0].srcObject;
		if(!!stream)
			stream.getTracks().forEach(x => x.stop());
	});
	if (!!window.plugins && !!window.plugins.waitingDialog) {
		window.plugins.waitingDialog.hide();
	}
}


window.addEventListener("load", () => {
	setupLogin();
	if (localStorage.getItem("email") != null) {
		const email = localStorage.getItem("email");
		$("#pre-populated-email").html(localStorage.getItem("email"));
		const emailInput = $("input[name='email']");
		if (emailInput.length > 0) {
			emailInput.val(email);
		}
	}
	if (localStorage.getItem("displayName") != null && localStorage.getItem("displayName") != 'null') {
		$("#pre-populated-display-name").html(localStorage.getItem("displayName"));
	} else {
		$("#pre-populated-display-name").hide();
	}
	if (localStorage.getItem("avatar") != null && localStorage.getItem("avatar") != 'null') {
		$("#pre-populated-avatar").show()
		$("#pre-populated-avatar").attr("src", "data:image;base64," + localStorage.getItem("avatar"));
	}

	if (!document.getElementById("barcodeScanner")) return;
	const updateCameraStatus = () => window.navigator.mediaDevices.enumerateDevices()
		.then(value => value.filter(val => val.kind === "videoinput")
			.filter(devices => devices.label.search(/ir\s/i) === -1).length > 0 ?
			document.getElementById("barcodeScanner").classList.remove("hidden") :
			document.getElementById("barcodeScanner").classList.add("hidden"));
	updateCameraStatus();
	navigator.mediaDevices.addEventListener("devicechange", updateCameraStatus);
});
