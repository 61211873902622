import {HelperCulture} from "./Helper.Culture";
import {HelperString} from "./Helper.String";

export class HelperLookup {

	static defaultLanguage = null;

	static getLookupQuery(lookupName, key, language) {
		return window.database[lookupName]
		.select("it.Value")
		.filter("it.Key === this.key && it.Language === language", { key: key, language: language });
	}

	static getLanguage(language) {
		if (language) {
			// @ts-ignore
			return new $.Deferred().resolve(language).promise();
		} else {
			return HelperCulture.languageCulture();
		}
	}

	static initializeArray(tableName, lookups) {
		const result = [];
		if (lookups.length > 1) {
			//for knockout.options binding use this dummy instead of optionsCaption
			const dummy = new window.database[tableName].createNew();
			dummy.Value = HelperString.getTranslatedString("PleaseSelect");
			dummy.Key = null;
			result.push(dummy);
		}
		result.push(...lookups);
		return result;
	}

	static getLocalizedQuery(tableName, language = null, filterExpression = null, filterParameters = null) {
		language = language || window.Helper.Lookup.defaultLanguage;
		let table;
		if (typeof tableName === "string") {
			table = window.database[tableName];
			if (!table) {
				throw new Error("table not found: " + tableName);
			}
		} else {
			table = tableName;
		}

		let query = table.filter("it.Language === this.language", {language: language});
		if (!!filterExpression) {
			query = query.filter(filterExpression, filterParameters);
		}
		return query
		.orderByDescending("it.Favorite")
		.orderBy("it.SortOrder")
		.orderBy("it.Value");
	}

	static getLocalized(tableName, language?, filterExpression?, filterParameters?) {
		return HelperLookup.getLocalizedQuery(tableName, language, filterExpression, filterParameters)
		.toArray()
		.then(function (lookups) {
			return HelperLookup.initializeArray(tableName, lookups);
		});
	}

	static getLocalizedArrayMap(tableName, language?, filterExpression?, filterParameters?, target?) {
		const deferred = $.Deferred();
		this.getLocalized(tableName, language, filterExpression, filterParameters)
		.then(function (result) {
			const map = HelperLookup.mapLookups(result, target);
			deferred.resolve(map);
		});
		return deferred.promise();
	}

	static getLocalizedArrayMaps(lookupMap, language = null) {
		const queries = [];
		Object.keys(lookupMap).forEach(function (key) {
			const target = window.ko.unwrap(lookupMap[key]) || {};
			if (!target.$tableName || target.$array || !window.database[target.$tableName]) {
				return;
			}
			if (target.$lazy) {
				const keys = Object.keys(target).filter((x) => !x.startsWith("$"));
				queries.push({
					tableName: target.$tableName,
					key: key,
					queryable: HelperLookup.getLocalizedQuery(target.$tableName, language, "it.Key in keys", { keys }),
					method: "toArray"
				});
			} else {
				queries.push({
					tableName: target.$tableName,
					key: key,
					queryable: HelperLookup.getLocalizedQuery(target.$tableName, language, target.$filterExpression, target.$filterParameters),
					method: "toArray"
				});
			}
		});
		if (!queries.length) {
			return $.Deferred().resolve().promise();
		}
		return window.database.batchExecuteQuery(queries).then(function (results) {
			results.forEach(function (lookups, i) {
				const tableName = queries[i].tableName;
				const key = queries[i].key;
				const result = HelperLookup.initializeArray(tableName, lookups);
				HelperLookup.mapLookups(result, window.ko.unwrap(lookupMap[key]));
			});
			return lookupMap;
		});
	}
	static mapLookups(lookups, target) {
		const map = lookups.reduce(function (m, entry) {
			m[entry.Key] = entry;
			return m;
		}, target || {});
		if (map["$array"]) {
			throw new Error("lookup contains a key '$array'");
		}
		map["$array"] = lookups;
		return map;
	}
	static setDefaultLanguage(language: string): void {
		HelperLookup.defaultLanguage = language;
	}

	static toArray(obj, sortProperty) {
		let result = Object.keys(obj).map(function (x) { return obj[x]; });
		if (sortProperty) {
			result = result.sort((a, b) => {
				if (a[sortProperty] > b[sortProperty]) {
					return -1;
				} else if (a[sortProperty] > b[sortProperty]) {
					return 1;
				}
				return 0;
			});
		}
		return result;
	}

	static getDefaultLookupValueSingleSelect(lookupMap, dbDefaultKey) {
		if (lookupMap.$array.length === 1) {
			return lookupMap.$array[0].Key;
		}
		const favorites = lookupMap.$array.filter((x) => x.Favorite);
		if (favorites.length === 1) {
			return favorites[0].Key;
		}
		if (favorites.length > 1) {
			return favorites.reduce(function (prev, current) {
				return (prev.SortOrder < current.SortOrder) ? prev : current
			}).Key;
		}
		const dbDefault = lookupMap[dbDefaultKey];
		return dbDefault ? dbDefaultKey : null;
	}

	static getLookupColor(lookups, key) {
		if (lookups) {
			const unwrappedKey = ko.unwrap(key);
			if (unwrappedKey !== null && unwrappedKey !== undefined) {
				const value = lookups[unwrappedKey];
				if (value && ko.unwrap(value.Color)) {
					return ko.unwrap(value.Color);
				}
			}
		}
		return "#9E9E9E";
	}

	static getLookupValue(lookup, key) {
		if (lookup) {
			const unwrappedKey = ko.unwrap(key);
			if (unwrappedKey !== null && unwrappedKey !== undefined) {
				const value = lookup[unwrappedKey];
				if (value) {
					return ko.unwrap(value.Value);
				}
			}
		}
		return "";
	}

	static queryLookupValue(lookupName, key, language) {
		return HelperLookup.getLanguage(language).then(function (lang) {
			// @ts-ignore
			const result = new $.Deferred();
			HelperLookup.getLookupQuery(lookupName, key, lang)
			.single()
			.then(function (value) {
				result.resolve(value);
			})

			.fail(function () {
				result.resolve(key);
			});
			return result.promise();
		});
	}

	static parseLookupFullName(fullName: string) {
		if (fullName === "Crm.Library.Globalization.Language"){
			return { fullName, plugin: "Main", pluginFullName: "Main", model: "Language", table: "Main_Language" };
		}
		let match = /(?<plugin>.*?)(\.Model\.Lookups\.|\.Model\.Lookup\.|\.Lookups\.|\.Model\.)(?<model>.*)/.exec(fullName);
		let pluginFullName = ["Crm", "Crm.Library"].includes(match.groups.plugin) ? "Main" : match.groups.plugin; 
		let plugin = pluginFullName.replace(/\./g, "");
		let model = match.groups.model;
		let table = plugin + "_" + model;
		return {
			fullName, plugin, pluginFullName, model, table
		};
	}

	static batchQueryLookupValue(options, language) {
		return HelperLookup.getLanguage(language).then(function (lang) {
			const queries = options.map(function (o) {
				return {
					queryable: HelperLookup.getLookupQuery(o.lookupName, o.key, lang),
					method: "single"
				};
			});
			return window.database.batchExecuteQuery(queries);
		});
	}

	static mapLookupForSelect2Display(lookup) {
		return {
			id: lookup.Key,
			item: lookup,
			text: lookup.Value
		};
	}

	static queryDefaultLookupKey(lookupName, defaultKey){
		return HelperLookup.getLocalizedQuery(lookupName)
			.take(1)
			.toArray()
			.then(function(results){
				if (results.length > 0){
					return results[0].Key;
				}
				return defaultKey;
			})
	}

	static queryLookup(lookupName, key, filterExpression, filterParameters) {
		if (!filterExpression && !filterParameters && key) {
			return HelperLookup.getLocalizedQuery(lookupName, null, "it.Value.toLowerCase().contains(this.Key) ", { Key: key });
		} else if (filterExpression && filterParameters) {
			return HelperLookup.getLocalizedQuery(lookupName, null, filterExpression, filterParameters);
		} else {
			return HelperLookup.getLocalizedQuery(lookupName);
		}
	}
	static getLookupByKeyQuery(lookupName, key) {
		return HelperLookup.getLocalizedQuery(lookupName, null, Array.isArray(key) ? "it.Key in this.Key" : "it.Key === this.Key", { Key: key });
	}

	static getAutocompleteOptions(tableName) {
		return {
			customFilter: HelperLookup.queryLookup,
			table: tableName,
			mapDisplayObject: HelperLookup.mapLookupForSelect2Display,
			getElementByIdQuery: HelperLookup.getLookupByKeyQuery
		};
	}

	static getMissingLookupText(lookupTypeTranslationKey) {
		return HelperString.getTranslatedString("MissingLookup").replace("{0}", HelperString.getTranslatedString(lookupTypeTranslationKey));
	}

	static filterLookupWithParent(query, term, parent) {
		// @ts-ignore
		query = query.filter("it.Language === language", { language: document.getElementById("meta.CurrentLanguage").content });
		if (term) {
			query = query.filter(function (it) {
					return it.Value.toLowerCase().contains(this.term);
				},
				{ term: term });
		}
		if (parent) {
			parent = parent.Value || parent;
			// @ts-ignore
			query = query.filter("it.Language === language && it.ParentName == parent",	{ language: document.getElementById("meta.CurrentLanguage").content, parent: parent });
		}
		return query
			.orderByDescending("it.Favorite")
			.orderBy("it.SortOrder")
			.orderBy("it.Value");
	}
}



