import {HelperUrl} from "./Helper.Url";
import { HelperString } from "./Helper.String";
import {HelperConfirm} from "./Helper.Confirm";
import {HelperLookup} from "./Helper.Lookup";

function appendToken(url: string): string {
	const tokentest = /([?&])token=([^&#]+)/.exec(window.location.search);
	const token = !!tokentest ? tokentest[2] : null;

	if (token && url && url.search(/([?&])+token=/) === -1) {
		const urlParts = url.split("?");
		const urlParams = new URLSearchParams(urlParts[1]);
		urlParams.append("token", token);
		url = urlParts[0] + "?" + urlParams.toString();
	}
	return url;
}

export class HelperUser {
	static currentUser;
	static getCurrentUserName() {
		const userElement = document.getElementById("meta.CurrentUser") as HTMLMetaElement;
		return userElement ? userElement.content : null;
	}
	static getCurrentUser() {
		if (HelperUser.currentUser){
			return $.Deferred().resolve(HelperUser.currentUser).promise();
		}
		const url = HelperUrl.resolveUrl("~/Users/CurrentUser.json");
		// @ts-ignore
		return $.ajax(url, { appendTokenIfRequired: true })
			.then(user => HelperUser.currentUser = user);
	}

	static async getCurrentUserPromise(): Promise<Crm.Rest.Model.Main_User> {
		if (HelperUser.currentUser){
			return new Promise<Crm.Rest.Model.Main_User>(resolve => resolve(HelperUser.currentUser));
		}
		const url = HelperUrl.resolveUrl("~/Users/CurrentUser.json");
		await fetch(appendToken(url))
			.then(result => result.json())
			.then(user => HelperUser.currentUser = user);
		return HelperUser.currentUser;
	}

	static getDisplayName(user: Crm.Rest.Model.Main_User | KnockoutObservable<Crm.Rest.Model.Main_User>): string {
		user = ko.unwrap(user);
		if (user === null) {
			return HelperString.getTranslatedString("Unspecified");
		}
		const tokens = [];
		if (ko.unwrap(user.LastName)) {
			tokens.push(ko.unwrap(user.LastName));
		}
		if (ko.unwrap(user.FirstName)) {
			tokens.push(ko.unwrap(user.FirstName));
		}
		if (tokens.length < 2 && ko.unwrap(user.Email)) {
			tokens.push(ko.unwrap(user.Email));
		}
		return tokens.join(", ");
	}
	static filterUserQuery(query, term, userGroupId) {
		if (term) {
			query = query.filter(function(it) {
					return it.FirstName.toLowerCase().contains(this.term) ||
						it.LastName.toLowerCase().contains(this.term)||
						it.Id.toLowerCase().contains(this.term);
				},
				{ term: term });
		}
		if (userGroupId) {
			userGroupId = userGroupId.Value || userGroupId;
			if (window.database.storageProvider.name === "webSql") {
				query = query.filter(function(it) {
					return it.UsergroupIds.contains(this.userGroupId);
				}, { userGroupId: `"${userGroupId}"` });
			} else {
				query = query.filter("it.UsergroupObjects.some(function(it2) { return it2.Id === this.userGroupId; })", { userGroupId: userGroupId });
			}
		}
		query = query.filter(function(it) {
			return it.Discharged === false;
		});
		return query;
	}

	static filterUsergroupQueryById(query, term) {
		if (term) {
			query = query.filter(function(it) {
					return it.Id === this.term;
				},
				{ term: term });
		}
		return query;
	}
	static languageFilter(query,term) {
		query = query.filter("it.IsSystemLanguage === true");
		return HelperLookup.queryLookup(query, term, undefined, undefined);
	}
	static mapForSelect2Display(user) {
		return {
			id: user.Id,
			item: user,
			text: HelperUser.getDisplayName(user)
		};
	}
	static mapRoleForSelect2Display(role) {
		role = window.Helper.Database.getDatabaseEntity(role);
		return {
			id: role.UId,
			item: role,
			text: HelperString.getTranslatedString(role.Name, role.Name)
		};
	}
	static mapUsergroupForSelect2Display(usergroup) {
		usergroup = window.Helper.Database.getDatabaseEntity(usergroup);
		return {
			id: usergroup.Id,
			item: usergroup,
			text: usergroup.Name
		};
	}

	static async toggleActive(user) {
		try {
			await HelperConfirm.genericConfirm({
				text: HelperString
					.getTranslatedString(user.Discharged() ? "ConfirmActivateUser" : "ConfirmDeactivateUser")
					.replace("{0}", ko.unwrap(user.Id)),
				type: "info"
			});
		} catch {
			return false;
		}
		window.database.attachOrGet(user.innerInstance);
		user.Discharged(!user.Discharged());
		user.DischargeDate(user.Discharged() ? new Date() : null);
		await window.database.saveChanges();
		return true;
	}

	static async resetGeneralToken(user) {
		try {
			await HelperConfirm.genericConfirm({
				text: HelperString
					.getTranslatedString("ConfirmResetToken")
					.replace("{0}", ko.unwrap(this.getDisplayName(user))),
				type: "info"
			});
		} catch {
			return false;
		}

		try {
			const result = await $.get(window.Helper.Url.resolveUrl("~/Account/ResetGeneralToken"), {username: user.Id()});
			window.database.attachOrGet(user.innerInstance);
			user.GeneralToken(result);
			await window.database.saveChanges();
			return true;
		} catch (e) {
			window.Log.error(e);
			window.swal(HelperString.getTranslatedString("UnknownError"),
				HelperString.getTranslatedString("Error_InternalServerError"),
				"error");
			return false;
		}
	};

	static async refreshUserCache(): Promise<void> {
		await fetch(window.Helper.Url.resolveUrl("~/UserAdmin/RefreshUserCache"), {redirect: "manual"});
	}

}

// @ts-ignore
(window.Helper = window.Helper || {}).User = HelperUser;