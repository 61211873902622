///<reference types="async"/>

import {HelperDOM} from "./Helper.DOM";
import {HelperUrl} from "./Helper.Url";

declare global {
	interface Window {
		Globalize: any;
	}
}

export class HelperCulture {

	static asyncLoading = false;
	static autoLoading = true;
	static initializeDeferred = null;

	static getGlobalizationData = function () {
		return $.ajax(HelperUrl.resolveUrl("~/Resource/GetGlobalizationData?format=json"), {
			async: HelperCulture.asyncLoading,
			cache: true,
			method: "GET"
		});
	}

	static languageCulture() {
		const deferred = $.Deferred();
		if ($("#meta\\.CurrentLanguage").attr("content")) {
			deferred.resolve($("#meta\\.CurrentLanguage").attr("content"));
		} else {
			HelperCulture.getGlobalizationData().then(function (result) {
				deferred.resolve(result.languageCultureName);
			});
		}
		return deferred.promise();
	}

	static culture() {
		const deferred = $.Deferred();
		if ($("#meta\\.CurrentCulture").attr("content")) {
			deferred.resolve($("#meta\\.CurrentCulture").attr("content"));
		} else {
			HelperCulture.getGlobalizationData().then(function (result) {
				deferred.resolve(result.cultureName);
			});
		}
		return deferred.promise();
	}
	static initialize() {
		if (HelperCulture.initializeDeferred){
			return HelperCulture.initializeDeferred.promise();
		}
		HelperCulture.initializeDeferred = $.Deferred();
		$.when(
			HelperCulture.culture()
		).then(function (cultureName) {
			const jsonFiles = [];
			const ajaxOptions = {async: HelperCulture.asyncLoading, cache: true};
			const cldrjsPaths = [
				"~/Content/js/system/cldrjs/supplemental/likelySubtags.json",
				"~/Content/js/system/cldrjs/main/{0}/ca-gregorian.json",
				"~/Content/js/system/cldrjs/main/{0}/currencies.json",
				"~/Content/js/system/cldrjs/main/{0}/dateFields.json",
				"~/Content/js/system/cldrjs/main/{0}/languages.json",
				"~/Content/js/system/cldrjs/main/{0}/localeDisplayNames.json",
				"~/Content/js/system/cldrjs/main/{0}/numbers.json",
				"~/Content/js/system/cldrjs/main/{0}/scripts.json",
				"~/Content/js/system/cldrjs/main/{0}/territories.json",
				"~/Content/js/system/cldrjs/main/{0}/timeZoneNames.json",
				"~/Content/js/system/cldrjs/main/{0}/variants.json",
				"~/Content/js/system/cldrjs/supplemental/currencyData.json",
				"~/Content/js/system/cldrjs/supplemental/likelySubtags.json",
				"~/Content/js/system/cldrjs/supplemental/numberingSystems.json",
				"~/Content/js/system/cldrjs/supplemental/ordinals.json",
				"~/Content/js/system/cldrjs/supplemental/plurals.json",
				"~/Content/js/system/cldrjs/supplemental/timeData.json",
				"~/Content/js/system/cldrjs/supplemental/weekData.json"
			];
			if (HelperCulture.asyncLoading) {
				// @ts-ignore
				async.eachOfSeries(cldrjsPaths,
					function(path, i, cb) {
						$.ajax(HelperUrl.resolveUrl(path.replace("{0}", cultureName)), ajaxOptions).then(function(result) {
							jsonFiles.push(result);
							cb();
						});
					},
					function() {
						window.Globalize.load(jsonFiles);
						window.Globalize.locale(cultureName);
						HelperCulture.initializeDeferred.resolve();
					});
			} else {
				cldrjsPaths.forEach(function (path) {
					jsonFiles.push($.ajax(HelperUrl.resolveUrl(path.replace("{0}", cultureName)), ajaxOptions).responseJSON);
					window.Globalize.load(jsonFiles);
					window.Globalize.locale(cultureName);
					HelperCulture.initializeDeferred.resolve();
				});
			}
		});
		return HelperCulture.initializeDeferred.promise();
	}
	static setMapLanguage(url) {
		if (url.includes("google")) {
			url = url + "&hl=" + document.getElementById("meta.CurrentLanguage").getAttribute("content");;
		}
		return url;
	}
	static getDisplayName(bcp47Tag, showScript = false, showVariant = false) {
		// @ts-ignore
		const cldr = new Cldr(bcp47Tag);
		const language = cldr.attributes.language;
		const script = cldr.attributes.script;
		const territory = cldr.attributes.territory;
		let variant = cldr.attributes.variant;
		const languageDisplay = window.Globalize.cldr.main("localeDisplayNames/languages/" + language);
		const territoryDisplay = window.Globalize.cldr.main("localeDisplayNames/territories/" + territory);
		const localeSeparator = window.Globalize.cldr.main("localeDisplayNames/localeDisplayPattern/localeSeparator");
		const pattern = window.Globalize.cldr.main("localeDisplayNames/localeDisplayPattern/localePattern");
		let result = pattern.replace("{0}", languageDisplay).replace("{1}", territoryDisplay);
		if (showScript === true && script) {
			const scriptDisplay = window.Globalize.cldr.main("localeDisplayNames/scripts/" + script);
			result = localeSeparator.replace("{0}", result).replace("{1}", scriptDisplay);
		}
		if (showVariant === true && variant) {
			if (variant.indexOf("-") === 0) {
				variant = variant.substr(1);
			}
			const variantDisplay = window.Globalize.cldr.main("localeDisplayNames/variants/" + variant);
			result = localeSeparator.replace("{0}", result).replace("{1}", variantDisplay);
		}
		return result;
	}
	static initializeLocaleSelect(element, target) {
		const $select = $(element);
		if ($select.length === 0) {
			return;
		}
		const $container = $select.parent("label");
		HelperDOM.blockElement($container);
		HelperCulture.initialize().then(function() {
			return $.get(HelperUrl.resolveUrl("~/Resource/ListLocales?format=json"));
		}).then(function (locales) {
			const options = locales.map(function (locale) {
				return {
					key: locale,
					language: locale.indexOf("-") === -1 ? locale : locale.substring(0, locale.indexOf("-")),
					value: HelperCulture.getDisplayName(locale)
				};
			}).sort(function (a, b) {
				return a.value.localeCompare(b.value);
			});
			const selectedValue = $select.data("value");
			options.forEach(function (option) {
				const selected = selectedValue === option.key ? " selected" : "";
				$select.append("<option value='" + option.key + "' class='" + option.language + "'" + selected + ">" + option.value + "</option>");
			});
			// @ts-ignore
			$select.chained(target);
			HelperDOM.blockElement($container, true);
		});
	}
}


export function initializeCulture() {

	$(function () {
		if (HelperCulture.autoLoading){
			HelperCulture.initialize();
		}
	});


}
