
export class HelperObject {

	// Removes properties which are falsy but not literally false (what about that? =) )
	// Cash me outside, how about that?
	static removeEmptyParams = (obj) => {
		const newObj = {};
		Object.keys(obj).forEach((prop) => {
			if (obj[prop] || obj[prop] === false) {
				newObj[prop] = obj[prop];
			}
		});
		return newObj;
	};

	static objectToString = (obj) => Object.values(obj).length > 0 ? `,${Object.values(obj).join(",")}` : "";

	static sortObject(object) {
		const sortedObj = {};
		const keys = Object.keys(object);
		keys.sort((key1, key2) => key1.toLowerCase().localeCompare(key2.toLowerCase()));
		for (const key of keys) {
			const value = object[key];
			if (value !== null && value !== undefined && typeof value === "object" && !(value instanceof Array)) {
				sortedObj[key] = HelperObject.sortObject(value);
			} else {
				sortedObj[key] = value;
			}
		}
		return sortedObj;
	}

	static localStorageAccessor() {
		throw new Error("This function was so bad I could not to migrate it, don't use it!");
	}
}
